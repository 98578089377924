import React, { useState } from 'react';
import styled, { ThemeConsumer } from 'styled-components';
import { FiChevronDown } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { PrisonersOfGeographyMetadata } from './blog/PrisonersOfGeography';
import { WeaponsOfMathDestructionMetadata } from './blog/WeaponsOfMathDestruction';
import { MindfckMetadata } from './blog/Mindfck';
import { ChipWarMetadata } from './blog/ChipWar';
import { HowToLieWithStatisticsMetadata } from './blog/HowToLieWithStatistics';
import { TheSixthExtinctionMetadata } from './blog/TheSixthExtinction';
import { HowTheWorldReallyWorksMetadata } from './blog/HowTheWorldReallyWorks';
import { HappinessHypothesisMetadata } from './blog/HappinessHypothesis';
import { WhereGoodIdeasComeFromMetadata } from './blog/WhereGoodIdeasComeFrom';
import { TheWorldForSaleMetadata } from './blog/TheWorldForSale';
import { ZeroToOneMetadata } from './blog/ZeroToOne';
import { FavoriteLibrariesMetadata } from './blog/FavoriteDataScienceLibraries';
import { TalkingToStrangersMetadata } from './blog/TalkingToStrangers';
import { InvisibleWomenMetadata } from './blog/InvisibleWomen';
import { OutliersMetadata } from './blog/Outliers';
import { FooledByRandomnessMetadata } from './blog/FooledByRandomness';
import { TheMythOfSisyphusMetadata } from './blog/TheMythOfSisyphus';
import { GunsGermsSteelMetadata } from './blog/GunsGermsAndSteel';
import { WhyLearnDataMetadata } from './blog/WhyLearnData';

const BlogContainer = styled.div`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f5f7;
  min-height: 100vh;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  margin-top: 2rem;
`;

const BlogCard = styled.div`
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
`;

const BlogImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`;

const BlogContent = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const BlogTitle = styled.h2`
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
  color: #2a2a2a;
  font-weight: bold;
  transition: color 0.3s;

  ${BlogCard}:hover & {
    color: #0077b5;
  }
`;

const BlogDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 1rem;
  flex-grow: 1;
`;

const DatePublished = styled.p`
  font-size: 0.9rem;
  color: #888;
  margin-top: auto;
  text-align: right;
  font-style: italic;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover .dropdown-menu {
    display: block;
  }
`;

const DropdownButton = styled.div`
  background-color: #adb5bd;
  color: white;
  border: none;
  padding: 0.6rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;

  &:hover {
    background-color: #6c757d;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 2.5rem;
  left: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  z-index: 10;
  display: none; /* Hidden by default */
  width: 320px;
  max-height: 200px;
  overflow-y: auto;
  transition: visibility 0.2s;
`;

const DropdownSearch = styled.input`
  width: 100%;
  padding: 0.4rem;
  margin-bottom: 0.5rem;
  border-radius: 8px;
  border: 1px solid #ccc;
`;

const DropdownItem = styled.div`
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const ClearButton = styled.button`
  background-color: #ff6b6b;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 1rem;

  &:hover {
    background-color: #d45555;
  }
`;

const Blog = () => {
  const navigate = useNavigate();
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const blogsData = [
    FavoriteLibrariesMetadata,
    PrisonersOfGeographyMetadata,
    WeaponsOfMathDestructionMetadata,
    MindfckMetadata,
    ChipWarMetadata,
    HowToLieWithStatisticsMetadata,
    TheSixthExtinctionMetadata,
    HowTheWorldReallyWorksMetadata,
    HappinessHypothesisMetadata,
    WhereGoodIdeasComeFromMetadata,
    TheWorldForSaleMetadata,
    ZeroToOneMetadata,
    TalkingToStrangersMetadata,
    InvisibleWomenMetadata,
    OutliersMetadata,
    FooledByRandomnessMetadata,
    TheMythOfSisyphusMetadata,
    GunsGermsSteelMetadata,
    WhyLearnDataMetadata
  ];

  const handleCardClick = (path) => {
    navigate(path);
  };

  const handleTagSelect = (tag) => {
    setSelectedTags((prev) =>
      prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]
    );
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory((prev) => (prev === category ? '' : category));
  };

  const handleClearFilters = () => {
    setSelectedTags([]);
    setSelectedCategory('');
  };

  const filteredBlogs = blogsData
    .filter((blog) =>
      selectedTags.length === 0
        ? true
        : blog.tags && selectedTags.every((tag) => blog.tags.includes(tag))
    )
    .filter((blog) => (selectedCategory === '' ? true : blog.category === selectedCategory))
    .sort((a, b) => new Date(b.datePublished) - new Date(a.datePublished));

  const filteredTagsList = Array.from(new Set(filteredBlogs.flatMap((blog) => (blog.tags ? blog.tags : []))));
  const filteredCategoriesList = Array.from(new Set(filteredBlogs.map((blog) => blog.category || '')));

  const filteredTags = filteredTagsList.filter((tag) =>
    tag && typeof tag === 'string' && tag.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <BlogContainer>
      <h1>Opinions & Reviews</h1>
      <br />
      <br />
      <FilterContainer>
        <DropdownContainer>
          <DropdownButton>
            Filter by Tags <FiChevronDown />
          </DropdownButton>
          <DropdownMenu className="dropdown-menu">
            <DropdownSearch
              type="text"
              placeholder="Search tags..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            {filteredTags.map((tag) => (
              <DropdownItem key={tag} onClick={() => handleTagSelect(tag)}>
                {tag}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </DropdownContainer>

        <DropdownContainer>
          <DropdownButton>
            Filter by Category <FiChevronDown />
          </DropdownButton>
          <DropdownMenu className="dropdown-menu">
            {filteredCategoriesList.map((category) => (
              <DropdownItem
                key={category}
                onClick={() => handleCategorySelect(category)}
              >
                {category}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </DropdownContainer>
      </FilterContainer>

      {(selectedTags.length > 0 || selectedCategory) && (
        <ClearButton onClick={handleClearFilters}>Clear Filters</ClearButton>
      )}

      <GridContainer>
        {filteredBlogs.map((blog, index) => (
          <BlogCard key={index} onClick={() => handleCardClick(blog.path)}>
            <BlogImage src={blog.coverImage} alt={blog.title} />
            <BlogContent>
              <BlogTitle>{blog.title}</BlogTitle>
              <BlogDescription>{blog.description}</BlogDescription>
              <DatePublished>
                <strong>Published:</strong> {blog.datePublished}
              </DatePublished>
            </BlogContent>
          </BlogCard>
        ))}
      </GridContainer>
    </BlogContainer>
  );
};

export default Blog;