import React from 'react';
import styled from 'styled-components';

// Styled Components
const PageContainer = styled.div`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f8fc;
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
`;

const Title = styled.h1`
  font-size: 2.8rem;
  color: #1a4c9c;
  text-align: center;
  margin-bottom: 1.5rem;
  line-height: 1.3;
`;

const DatePublished = styled.p`
  font-size: 1.1rem;
  color: #666666;
  margin-bottom: 2rem;
  text-align: center;
`;

const ContentSection = styled.div`
  width: 100%;
  max-width: 1200px;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
  margin-bottom: 2.5rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.9rem;
  color: #1a4c9c;
  border-bottom: 3px solid #1a4c9c;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
`;

const TextContent = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  color: #333333;
  text-align: justify;
`;

const TwoColumnLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  flex: 1;
  min-width: 300px;
  text-align: justify;
`;

const Image = styled.img`
  width: 100%;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
`;

const StyledList = styled.ul`
  margin-top: 1rem;
  padding-left: 1.5rem;
`;

const ListItem = styled.li`
  margin-bottom: 0.8rem;
  line-height: 1.8;
`;

const Button = styled.button`
  margin-top: 2.5rem;
  padding: 0.8rem 2.5rem;
  font-size: 1.1rem;
  color: #fff;
  background-color: #1a4c9c;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0f3570;
  }
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  gap: 0.5rem;
`;

const Tag = styled.span`
  background-color: #1a4c9c;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  font-size: 0.9rem;
`;

// Metadata for SEO
export const WhyLearnDataMetadata = {
  title: "Why Learn Data?",
  description: "Exploring the power of data skills in today’s digital world and why they matter for your career.",
  tags: [
    'Data Science', 'Analytics', 'SQL', 'Power BI', 'Python', 'Career Growth'
  ],
  datePublished: "2025-02-10",
  category: "Article",
  path: "/article/why-learn-data",
  coverImage: "/images/data_analysis.jpg",
};

// Blog Component
const WhyLearnData = () => {
  const metadata = WhyLearnDataMetadata;

  return (
    <PageContainer>
      <Title>{metadata.title}</Title>
      <Image src={metadata.coverImage} alt={metadata.title} style={{ maxWidth: '700px', marginBottom: '2rem' }} />
      <DatePublished>Date Published: {metadata.datePublished}</DatePublished>
      <TagContainer>
        {metadata.tags.map((tag, index) => (
          <Tag key={index}>{tag}</Tag>
        ))}
      </TagContainer>
<br />
<br />
      {/* First Section */}
      <ContentSection>
        <SectionTitle>The Power of Data in Today’s World</SectionTitle>
        <TextContent>
        The digital revolution over the past 30 years has reshaped not only the job market but also the way we live, interact, and make decisions. <strong>With the rise of automation, AI, and digital platforms, we now generate massive amounts of data every time we browse, shop, or engage online</strong>. Businesses, governments, and organizations rely on this data to refine strategies, improve efficiency, and enhance customer experiences.
        <br /><br />
        </TextContent>
        <TwoColumnLayout>
        <Column>
            <Image src="/images/skyline.jpg" alt="Changing Landscape" />
          </Column>
          <Column>
            <TextContent>
            From small businesses to global corporations, the push for data-driven decision-making is stronger than ever. Companies are heavily investing in analytics, AI, and business intelligence tools to remain competitive.
            <br /><br />    
            Professionals in marketing, finance, healthcare, and beyond are expected to use data to optimize performance, cut costs, and drive innovation. Whether it’s personalizing customer experiences, predicting market trends, or improving healthcare outcomes, <strong>data is at the center of modern decision-making</strong>.

            </TextContent>
          </Column>
          
        </TwoColumnLayout>
      </ContentSection>

      {/* Second Section */}
      <ContentSection>
        <SectionTitle>Why Data Skills Matter for Your Career</SectionTitle>
        <TextContent>
        If you’re wondering whether learning about data is worth the effort, consider this: according to a <a href="https://www.indeed.com/career-advice/finding-a-job/in-demand-skills" 
          target="_blank" rel="noopener noreferrer">2025 report from Indeed</a>, <strong>the fourth most in-demand skill in today’s job market is “Analysis". </strong> Employers are actively searching for professionals who can break down complex problems, extract meaningful insights, and provide solutions efficiently. Indeed’s report states:

          “Companies look for employees who are great at investigating a problem and finding the ideal solution efficiently and timely. <strong>People with strong analytical skills are in high demand in many industries</strong>.”
      

        </TextContent>
        <br />  
        <TwoColumnLayout>
          <Column>
          <TextContent>
          This means that learning to leverage the vast amount of available data is not just beneficial, it’s becoming a necessity. Whether you’re in business, technology, or even creative fields, knowing how to work with data gives you a significant advantage.
            <br /><br />    
            Think of it this way: <strong>companies that fail to embrace data-driven decision-making risk falling behind their competitors</strong>. The ability to collect, clean, analyze, and visualize data is a powerful tool that allows businesses to optimize performance, cut costs, and predict future trends.

            </TextContent>
          </Column>
          <Column>
            <Image src="/images/data_teamwork.jpg" alt="Team Analyzing Data" />
          </Column>
        </TwoColumnLayout>
      </ContentSection>

      {/* Why I Started This Blog Section */}
      <ContentSection>
        <SectionTitle>What's Next</SectionTitle>
        <TextContent>
          I am starting this blog series for two main reasons:
        </TextContent>
        <StyledList>
          <ListItem><strong>To Reinforce My Own Learning </strong> – One of the best ways to truly understand a topic is to teach it. Writing about data concepts helps solidify my knowledge and pushes me to explore new ideas.</ListItem>
          <ListItem><strong>To Help Others Learn </strong> – Whether you’re a beginner just getting started or someone looking to sharpen your skills, I hope these posts provide value and motivation.</ListItem>
        </StyledList>
        
        <TwoColumnLayout>
            <Column>
            < Image src="/images/stay_tuned.jpg" alt="Stay Tuned" />
            </Column>
            <Column>
            <TextContent>
          This blog series aims to demystify data concepts and provide hands-on examples so that anyone, regardless of experience, can learn.  
          Each week, I'll cover a new topic, including:
        </TextContent>
        <StyledList>
          <ListItem><strong>Data Fundamentals</strong> – Understanding how data is structured and why it matters.</ListItem>
          <ListItem><strong>SQL</strong> – Learning how to query and manipulate databases.</ListItem>
          <ListItem><strong>Excel & Power BI</strong> – Creating dashboards and powerful reports.</ListItem>
          <ListItem><strong>Python for Data Science</strong> – Automating analysis, building models, and working with large datasets.</ListItem>
        </StyledList>
        </Column>
        </TwoColumnLayout>
      </ContentSection>

      <Button onClick={() => window.history.back()}>Back to Blog</Button>
    </PageContainer>
  );
};

export default WhyLearnData;