import React from 'react';
import styled from 'styled-components';

// Main Container for the book review
const BookReviewContainer = styled.div`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f1e3; /* Cream background */
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
`;

// Title styling
const Title = styled.h1`
  font-size: 2.5rem;
  color: #002b36; /* Dark teal */
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

// Author styling
const Author = styled.p`
  font-size: 1.2rem;
  color: #555; /* Gray for subtle contrast */
  margin-bottom: 1rem;
  font-style: italic;
`;

// Cover Image styling
const CoverImage = styled.img`
  width: 100%;
  max-width: 400px;
  max-height: 600px;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
`;

// Date Published styling
const DatePublished = styled.p`
  font-size: 1rem;
  color: #9a7d0a; /* Gold accent */
  margin-bottom: 2rem;
`;

// Content Section styling
const ContentSection = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
  background-color: #ffffff; /* White */
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
`;

// Section Title styling
const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #002b36; /* Dark teal */
  border-bottom: 2px solid #9a7d0a; /* Gold border */
  padding-bottom: 0.5rem;
`;

// Section Content styling
const SectionContent = styled.div`
  font-size: 1rem;
  line-height: 1.8;
  color: #333; /* Darker gray for text */
`;

// Quote styling
const Quote = styled.blockquote`
  font-style: italic;
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: #f4f4f4; /* Light gray */
  border-left: 4px solid #9a7d0a; /* Gold border */
  border-radius: 4px;
  color: #555;
`;

// List Item styling
const ListItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;
`;

// Ordered List styling
const OrderedList = styled.ol`
  padding-left: 1rem; /* Adjust padding to align with the section title */
`;

// Tags Container styling
const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;

// Tag styling
const Tag = styled.span`
  background-color: #002b36; /* Dark teal */
  color: #f7f1e3; /* Cream text */
  padding: 0.4rem 0.7rem;
  border-radius: 12px;
  font-size: 0.8rem;
`;

const Button = styled.button`
  display: inline-block;
  margin: 2rem 0;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: #f7f1e3; /* Cream */
  background-color: #002b36; /* Dark teal */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;

  &:hover {
    background-color: #9a7d0a; /* Gold */
    color: #000;
  }
`;

export const GunsGermsSteelMetadata = {
  title: "Guns, Germs, and Steel",
  description: "A short history of everybody for the last 13,000 years.",
  tags: [
    'History', 'Anthropology', 'Geography', 'Sociology',
    'Civilization', 'Science', 'Culture'
  ],
  author: "Jared Diamond",
  datePublished: "2025-02-02",
  category: "Book Review",
  path: "/book-review/guns-germs-and-steel",
  coverImage: "/images/gun_germs_steel.jpg",
};

const GunsGermsSteel = () => {
  const metadata = GunsGermsSteelMetadata;

  return (
    <BookReviewContainer>
      <Title>{metadata.title}</Title>
      <Author>by {metadata.author}</Author>
      <CoverImage src={metadata.coverImage} alt={metadata.title} />
      <DatePublished>Date Published: {metadata.datePublished}</DatePublished>
      <TagContainer>
        {metadata.tags.map((tag, index) => (
          <Tag key={index}>{tag}</Tag>
        ))}
      </TagContainer>
      <br />
      <br />

      <ContentSection>
        <SectionTitle>Summary</SectionTitle>
        <SectionContent>
          <p>
            "Guns, Germs, and Steel" explores the factors that shaped the modern world by examining why certain civilizations advanced faster than others. Jared Diamond argues that geography, not inherent differences among people, played the key role in shaping history.
          </p>
          <br />
          <p>
            The book investigates the influence of agriculture, domestication, disease, and technological advancements on the development of societies. Diamond debunks traditional narratives of cultural superiority and instead presents a compelling argument for environmental determinism.
          </p>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Key Themes or Concepts</SectionTitle>
        <SectionContent>
          <OrderedList>
            <ListItem><strong>Geographic Determinism:</strong> The impact of geography and environment on societal development.</ListItem>
            <ListItem><strong>Agriculture and Domestication:</strong> How early access to food production shaped civilization.</ListItem>
            <ListItem><strong>Disease as a Weapon:</strong> How germs and immunity shaped global conflicts.</ListItem>
            <ListItem><strong>Technology and Innovation:</strong> The role of steel, writing, and tools in shaping power structures.</ListItem>
            <ListItem><strong>Collapse of Societies:</strong> How environmental and cultural factors led to the downfall of civilizations.</ListItem>
          </OrderedList>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Favorite Quotes</SectionTitle>
        <SectionContent>
          <Quote>
            "History followed different courses for different peoples because of differences among their environments, not because of biological differences among the peoples themselves."
          </Quote>
          <Quote>
            "The striking differences between the long-term histories of peoples of the different continents have been due not to innate differences in the peoples themselves but to differences in their environments."
          </Quote>
        </SectionContent>
      </ContentSection>

      {/* Personal Reflection Section */}
      <ContentSection>
        <SectionTitle>Personal Reflection</SectionTitle>
        <SectionContent>
          <p>
            I picked up this book after a recommendation from my Environmental Science course professor, expecting a deep dive into the role of geography in shaping civilizations. What I got was a well-structured argument on how access to resources, agriculture, and immunity shaped the balance of power throughout history. It was an interesting read that explained a lot of patterns I had come across before but never really thought about in this way.
          </p>
          <br />
          <p>
            While reading, I kept thinking back to <a href="/book-review/prisoners-of-geography">Prisoners of Geography</a>. That book looks at how geography still influences modern politics, and I found that it complemented Diamond’s ideas well. Where Diamond explains why some civilizations advanced earlier, Tim Marshall examines how those early advantages still play a role in today’s world. Seeing those connections helped me understand how much of history is shaped by things outside human control.
          </p>
          <br />
          <p>
            Another book that came to mind was <a href="/book-review/the-world-for-sale">The World for Sale</a>. Diamond explains how control over food, weapons, and technology helped certain civilizations expand, and that book takes it into the present by showing how global trade in commodities—oil, food, and metals—still drives economic and political power. It was interesting to see how the same themes apply whether we’re talking about ancient empires or modern corporations.
          </p>
          <br />
          <p>
            One of the more thought-provoking aspects was how early inequalities compounded over time, which made me think of <a href="/book-review/weapons-of-math-destruction">Weapons of Math Destruction</a>. Diamond focuses on historical advantages like agriculture and immunity, while that book looks at how modern systems—like biased algorithms—continue to create disparities. Both books highlight how initial advantages, whether in history or in data, tend to reinforce themselves over time.
          </p>
        </SectionContent>
        </ContentSection>
      <Button onClick={() => window.history.back()}>Back to Blog</Button>
    </BookReviewContainer>
  );
};

export default GunsGermsSteel;